/*
 * @Author: xiaogong 508089805@qq.com
 * @Date: 2022-06-13 20:14:10
 * @LastEditors: xiaogong 508089805@qq.com
 * @LastEditTime: 2022-07-06 20:06:10
 * @FilePath: /jude/src/request/api.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { get, post } from './http';

//用户信息接口
export const userinfo = p => get('/api/user/info', p);
//切换用户登录
export const changeUser = p => post('/api/user/switchLogin', p);

//获取验证码

export const getyzm = p => get('/api/image_captcha', p);

//授权信息
export const authorizationInfo = p => get('/api/authorizationInfo', p);

//修改代理商密码

export const UpdateUser = p => post('/api/user/update', p);
//广告代理商公司列表
export const advertising = p => post('/api/depart/page', p);
//添加或修改广告代理商公司
export const Addadvertising = p => post('/api/depart/add', p);
//代理商账户列表
export const Accountmanage = p => post('/api/user/list', p);
//添加或修改代理商账户
export const AddAccountManage = p => post('/api/user/add', p);
//广告代理商公司菜单列表
export const AdvertisingList = p => get('/api/depart/list', p);
//删除代理商账户
export const DelAccount = p => post('/api/user/del', p);

//获取数据看板统计
export const getdataboard = p => post('/api/databoard/statistic', p);


//数据看板-消耗时间趋势
export const getcosttrend = p => post('/api/databoard/costtrend', p);

//代理商公司列表
export const listOfAgents = p => post('/api/listOfAgents', p);

//添加代理商
export const addAgents = p => post('/api/addAgents', p);

//修改代理商
export const editAgents = p => post('/api/editAgents', p);

//删除代理商
export const delAgents = p => post('/api/delAgents', p);

//代理商账号管理列表
export const agentAccountList = p => post('/api/agentAccountList', p);

//获取代理商公司
export const getFactor = p => post('/api/getFactor', p);

//添加代理-账号
export const addAgentAccount = p => post('/api/addAgentAccount', p);

//修改代理商账号
export const editAgentAccount = p => post('/api/editAgentAccount', p);

//删除代理商账号
export const delAgentAccount = p => post('/api/delAgentAccount', p);

//导出数据看板报表

export const getkanbanplan = p => post('/api/campaign/downloadAgentData', p);
//获取数据看板代理商数据
export const getagent = p => post('/api/databoard/agent', p);

//获取联盟订单 
export const getorders = p => post('/api/unionOrders', p);

//导出联盟订单 
export const unionOrdersExport = p => post('/api/unionOrdersExport', p);

//获取联盟订单类型列表 
export const getAccountTypeList = p => get('/api/getAccountTypeList', p);
//订单统计
export const statistics = p => post('/api/statistics', p);
//收藏

export const collect = p => post('/api/collect', p);
//联盟订单分组

export const accGroup = p => post('/api/accGroup', p);
//广告平台分组
export const platform = p => get('/api/adplatform', p);

//营销活动监控
export const activitylist = p => get('/api/getPcTree', p);//营销活动监控第一个筛选
export const accountlis = p => get('/api/accountList', p);//营销活动监控第二个筛选中账户列表接口
export const advertisement = p => get('/api/campaignList', p);//营销活动监控第二个筛选中广告列表接口
export const plan = p => get('/api/planSearch', p);//营销活动监控第二个筛选中计划列表接口
export const commodity = p => get('/api/goodsList', p);//营销活动监控第三个筛选中计商品表接口
export const dataoverview = p => post('/api/campaignTrack/countNumByHour', p);//营销活动监控实时数据概览
export const coreindicators = p => post('/api/cornMetricsByKeyId', p);//营销活动监控首页 - (账户、广告组、计划 -[核心指标])
export const monitoringdata = p => post('/api/campaignTrack/getCurrentDataTop20ByType', p);//营销活动监控首页页签获取账户、广告组、计划接口
export const realtimetrend = p => post('/api/campaignTrack/countNumByPerHour', p);//营销活动监控实时趋势
export const productlaunch = p => post('/api/campaignTrack/good/effect', p);//营销活动监控商品投放效果
export const timetrend = p => post('/api/temporalTrend', p);//营销活动监控首页-(账户 广告组 计划 [ 时间趋势 ] 时间报表)
// 报表自定义列
export const columndefault = p => post('/api/customCol/findDefaultCustomCol', p);//营销活动监控首页-(账户 广告组 计划 系统默认列
export const columndeuser = p => post('/api/customCol/findCustomColByUser', p);//营销活动监控首页-(账户 广告组 计划 用户自定义列
export const columnpreservation = p => post('/api/customCol/saveCustomColByUser', p);//营销活动监控首页-(账户 广告组 计划 保存用户自定义列
//报表下载表格
export const downloadPlanData = p => post('/api/campaign/downloadPlanData', p);//报表计划数据-下载表格
export const downloadChildAccountData = p => post('/api/campaign/downloadChildAccountData', p);//报表子账户数据-下载表格
export const downloadMainAccountData = p => post('/api/campaign/downloadMainAccountData', p);//报表主账户数据-下载表格
export const downloadCompanyData = p => post('/api/campaign/downloadCompanyData', p);//报表公司数据-下载表格
export const downloadDetailData = p => post('/api/campaign/downloadTrendDetailData', p);//报表公司数据-下载表格

//营销活动报表
export const bbdataoverview = p => post('/api/campaign/effectAnalysisDataOverview', p);//营销活动报表实时数据概览

export const productlauncheffect = p => post('/api/campaign/goodsEffect', p);//营销活动报表商品投放效果
export const reporttimetrend = p => post('/api/campaign/effectAnalysisServingTrend', p);//营销活动报表时间趋势
export const downloadform = p => post('/api/campaign/downloadAnalysisServingTrend', p);//营销活动报表报表时间趋势-下载表格
export const dailyReportByTab = p => post('/api/campaign/dailyReportByTab', p);//营销活动报表时报表tab页接口
export const cornMetricsByKeyId = p => post('/api/campaign/cornMetricsByKeyId', p);//营销活动报表时报表核心指标
export const metricsTrendReport = p => post('/api/campaign/metricsTrendReport', p);//营销活动报表时报表详细分析
export const planDetail = p => post('/api/campaign/planDetail?planId=1732880310352909', p);//营销活动报表时报表计划详情
export const list = p => post('/api/material/list', p);//营销活动报表计划关联视频素材
export const getMaterialDetail = p => post('/api/material/getMaterialDetail', p);//营销活动报表计划关联视频素材详情
export const interactiveAnalysisV2 = p => post('/api/material/interactiveAnalysisV2', p);//营销活动报表计划关联视频素材-互动分析
export const timeTrendInterface = p => post('/api/material/timeTrendInterface', p);//营销活动报表计划关联视频素材-时间趋势
export const materialCellTop10Detail = p => post('/api/material/materialCellTop10Detail', p);//营销活动报表计划关联视频素材-关联计划详情
export const materialDetails = p => post('/api/material/materialDetails', p);//营销活动报表计划关联视频素材-单一素材详情

// 报表新接口
export const planningdata = p => post('/api/campaign/plan', p);//报表-计划数据（新）
export const subaccountdata = p => post('/api/campaign/child_account', p);//报表-子账户数据（新）
export const masteraccountdata = p => post('/api/campaign/account', p);//报表-主账户数据（新）
export const advertisercompany = p => post('/api/campaign/company', p);//报表-广告主公司数据（新）




export const activicySetting = p => post('/api/activicySetting',p);   // 活动配置设置     
export const platForm = p => get('/api/adplatform',p);   // 下拉菜单 -- 巨量or腾讯      
export const getscreen = p => get('/api/account/list?pid=0',p);   // 营销活动分析的第一个筛选框      
export const getnid = p =>get('/api/account/name_id/list',p);    // 营销活动分析的第二三个筛选框
export const getstatus = p =>get('/api/plan/status/list',p)     // 营销活动分析的第四个筛选框
export const getplanid = p =>get('/api/plan/id/list',p)     // 营销活动分析的第五个筛选框
export const timechart = p =>post('/api/chart/real_time',p)     // 营销活动分析  实时趋势汇总
export const timetable = p =>post('/api/table/real_time',p)     // 实时小时报表     
export const hchart = p =>post('/api/chart/history',p)   // 历史趋势汇总
export const htable = p =>post('/api/table/history',p)   // 历史天报表   
export const getactivicy = p =>post('/api/activicyList',p);     // 活动配置 > 活动 > 活动名称
export const saveedit = p =>post('/api/activitymanagement/addActivity',p)  // 新建活动的 保存和编辑            
export const relation = p =>post('/api/getFirstSecondAccount',p)  // 关联账户
// export const relation = p =>post('/api/getFirstSecondAccountCeshi_v2',p)    // 关联账户列表（新）
export const edit = p =>post('/api/editActivityManagement',p)   // 编辑详情
export const delactive = p =>post('/api/delActivity',p)     // 删除活动
export const planlist = p =>post('/api/planList',p)     // 计划列表
export const down = p =>post('/api/export?activityId=1&page=1&pageSize=9999999&urlCompletion=&status=%5B%22AD_STATUS_DELIVERY_OK%22%5D',p)     // 导出excel
export const planmanage = p =>post('/api/PlanManagementList',p)     // 计划管理下的列表

// 公司管理
export const companylist = p =>post('/api/companyList',p)   // 公司列表
export const addcompany = p =>post('/api/addCompany',p)   // 添加公司
export const editcompany = p =>post('/api/editCompany',p)   // 编辑公司信息
export const accountlist = p =>post('/api/accountList',p)   // 主账户管理列表
export const addaccount = p =>post('/api/addAccount',p)   // 添加主账户
export const delagent = p =>post('/api/delAgent',p)   // 删除主账户
export const getAccountById = p =>post('/api/getAccountById',p)   // 获取子账户列表
export const getChildAccountPlan = p =>post('/api/getChildAccountPlan',p)   // 获取子账户计划列表
export const asyncSubAccount = p =>post('/api/asyncSubAccount',p)   // 获取子账户
export const editPrincipalAccount = p => post('api/editPrincipalAccount',p)   //编辑主账户
// 子账号管理
export const addChildAccount = p => post('/api/user/addChildAccount',p)   // 添加子账号
export const childAccount = p => post('/api/user/accountList',p)   // 子账号列表

export const getChildList = p => post('/api/getAccountList',p) //联盟订单子账号列表

export const menulist = p => get('/api/menuList',p)   // 菜单权限
export const editAccount = p => post('/api/user/editAccount',p)   // 修改子账号
export const delAccount = p => post('/api/user/delAccount',p)   // 删除子账号





export const getooauth = p => get('/api/toutiao_oauth_url',p)   //获取巨量授权链接
export const toauth = p => get('/api/alimama_oauth_url',p)   //获取淘宝联盟授权链接
export const toutiaolist = p => get('/api/toutiao_list',p)   //获取巨量授权用户列表
export const taobaolist = p => get('/api/alimama_list',p)   //获取淘宝联盟授权用户列表
export const orelieveoauth = p => get('/api/toutiao_relieve_oauth',p)   //解除巨量授权
export const trelieveoauth = p => get('/api/alimama_relieve_oauth',p)   //解除淘宝联盟授权
export const convertlinks = p => post('/api/convertlinks',p)   //解除淘宝联盟授权

export const checktaobaostatus = p => get('/api/check_taobao_status',p)   //获取淘宝联盟授权状态

export const login = p => post('/api/user/login',p)   //登录接口
export const recalldata = p => get('/api/recall_data',p)   //登录接口

export const editPwd = p => post('/api/user/password',p)   //登录接口

export const domain_get = p => get('/api/domain/get',p)   //获取绑定的域名
export const domain_add = p => post('/api/domain/add',p)   //获取绑定的域名

//数据中心
export const orderData = p => post('/api/dataCenter/orderData', p) // 订单数据
export const dataCenter = p => post('/api/orderDetail', p) // 订单明细
export const createdOrderTask = p => post('/api/createdOrderTask', p) // 生成订单下载任务 
export const listOrderTask = p => post('/api/listOrderTask', p) // 订单下载任务列表
export const agentlist = p => get('/api/agent/list', p) // 代理商列表
export const delOrder = p => post('/api/delOrderTask', p) // 删除订单任务
export const agentList = p => get('/api/agentList', p) // 订单明细筛选框

export const isDdSwitch = p =>post('/api/user/show_data',p) //报表是否显示

export const addTbMerchandiseList = p =>post('api/addTbMerchandiseList',p)  //淘宝商品列表
export const addTbMerchandise = p =>post('api/addTbMerchandise',p)  //添加商品
export const goods_delete = p =>get('api/goods/delete',p)  //删除商品
export const goods_detail = p =>get('api/goods/detail',p)  //获取商品详细信息
export const landingpagelist = p =>post('api/loadingpage/list',p)  //配置落地页
export const alimama_multi = p =>get('api/alimama/multi',p)  //配置落地页
export const alimama_status = p =>get('api/alimama/status',p)  //配置落地页
export const upload = p =>post('api/upload',p,{isUpload: true })  //上传文件
export const upload_certificate = p =>post('api/upload_certificate',p,{isUpload: true })  //上传证书
export const save_certificate = p =>post('api/save_certificate',p,{isUpload: true })  //上传证书
export const loadingpage_add = p => post('api/loadingpage/add', p)  //上传页面
export const loadingpage_detail = p => post('api/loadingpage/detail', p)  //页面详细
export const loadingpage_list = p => get('api/loadingpage/list', p)  //页面列表
export const loadingpage_delete = p => post('api/loadingpage/delete', p) // 删除

export const ding_config = p => get('api/ding_config',p)//获取钉钉配置

export const change_use_order = p => post('api/change_use_order',p)   //修改alimama表use_order字段
export const no_audit_agent = p => get('api/no_audit_agent/list',p)   //待审核授权列表
export const agent_audit = p => get('api/agent/audit',p)   //授权审核
export const createoneurl = p => get('api/goods/createoneurl',p)   //授权审核
export const switchUser = p => post('api/user/switch',p)   //授权审核
export const userOpen = p => post('/api/user/open',p)   //修改跑量助手状态
export const miniapplist = p => get('api/miniapp/list',p)   //小程序绑定列表
export const miniappadd = p => post('api/miniapp/add',p)   //小程序绑定添加
export const miniappdel = p => post('api/miniapp/del',p)   //小程序绑定删除
export const miniappuserlist = p => get('api/miniapp/user/list',p)   //小程序绑定用户列表
export const switch_auth = p => get('api/switch/auth',p)   //添加回传鉴权
export const auth_enable = p => get('api/auth/enable',p)   //开启关闭回传鉴权
export const getCost = p => post('api/user/cost',p)   //获取账号总花费
export const status_v2 = p => post('api/agent/status_v2',p)   //新版数据开关
export const createtrack = p => get('api/track/create',p)   //生成监测链接
export const get_jd_cid_info = p => get('api/goods/cid',p)   //获取cid信息

export const save_jd_cid_info = p => post('api/goods/cidsave',p)   //保存Cid信息
